<div id="activities">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">National Cadet Corps (NCC) - Army Wing</h4>
                    <div class="card-body text-justify">
                        <div class="text-center mb-3">
                            <img src="/assets/img/activities/ncc.jpg" alt="" class="img-fluid rounded">
                        </div>
                        <p class="inner-lead">The NCC Junior Troop at our school was established in the year 2006 and
                            operates under the 10 Haryana Battalion. The troop is led by Associate NCC Officer, Ms.
                            Bindu Rani, and comprises 50 Junior Wing (JW) girls and 50 Junior Division (JD) boys.
                        </p>
                        <p class="inner-lead">Our cadets actively participate in the Independence Day (15th August) and
                            Republic Day (26th January) parades. They also attend various camps, including the 10-day
                            Combined Annual Training Camp (CATC), where they undergo training in drill, map reading,
                            firing and engage in awareness activities like the Swachhata Abhiyan (Cleanliness Drive).
                        </p>
                        <p class="inner-lead">The NCC motto, “Unity and Discipline,” inspires our cadets to uphold the
                            highest values of teamwork and responsibility. The NCC course spans two years, culminating
                            in the NCC ‘A’ Certificate Examination in the second year. Cadets who pass the exam are
                            awarded the prestigious ‘A’ Certificate.
                        </p>
                        <p class="inner-lead">Our cadets have also excelled at the national level. Notably, Cadets
                            Arshdeep Kaur and Arundhati Gaur represented our school in the Republic Day Camp (RDC) in
                            the years 2019-20 and 2010-11, respectively.
                        </p>
                        <p class="inner-lead">We take immense pride in the achievements of our cadets and their
                            unwavering commitment to the values of the NCC.</p>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrcAll" [render-text]="true" [original-size]="false"
                            style="display: block;" (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <!-- <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc6"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc1"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc2"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc3"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc4"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc5"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div> -->
                </div>

            </div>
        </div>


    </div>
</div>