<div id="all-publication" class="mt-3">
    <div class="container px-lg-5">
        <div class="row" *ngIf="publication">
            <div class="col-12">
                <h2 class="text-center card-title">{{publication.title}} ({{publication.date}})</h2>
                <p class="mb-4">
                </p>
                <!-- <div class="social d-flex justify-content-end mt-2 mr-5">
                    <app-button
                    type='facebook'
                    [shareUrl]='link'>
                  </app-button>
                  
                  <br>
                
                  <app-button
                    type='twitter'
                    [shareUrl]='link'>
                  </app-button>
                  <app-button
                    type='whatsapp'
                    [shareUrl]='link'>
                  </app-button>
                </div> -->
                <div class="row text-center mb-4" *ngIf="!(publication.imgUrl.includes('.pdf'))">
                    <div class="col-12">
                        <a href="{{publication.imgUrl}}" data-lightbox="Publication"> <img [src]="publication.imgUrl"
                                alt="{{publication.title}}" class="img-fluid rounded"></a>
                    </div>
                </div>
                <div class="row text-center mb-4" *ngIf="(publication.imgUrl.includes('.pdf'))">
                    <div class="col-12">
                        <iframe [src]="sanitizedPdfUrl" width="100%" height="950px" frameborder="0" allowfullscreen>
                        </iframe>
                    </div>
                </div>
                <p class="mb-4 inner-lead">
                    {{publication.desc}}
                </p>
                <a href="{{publication.imgUrl}}" class="btn btn-primary text-white mb-4" target="_blank" download
                    *ngIf="(publication.imgUrl.includes('.pdf'))">Download PDF</a>
            </div>
        </div>
        <div class="row" *ngIf="!publication">
            <div class="col-12">
                <h2 class="text-center card-title">Notices</h2>
                <p class="my-4 inner-lead">There maybe no data for this!</p>
            </div>
        </div>
    </div>
</div>