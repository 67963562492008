import { Component, OnInit, HostListener } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/activities/workshop2023_2.pdf','/assets/pdf/activities/workshop2022_2.pdf','/assets/pdf/activities/workshop2022_2.pdf','/assets/pdf/activities/workshopyoga.pdf','/assets/pdf/activities/CAREER GUIDANCE.pdf','/assets/pdf/activities/EXPERT SESSION ON HOLISTIC GROWTH.pdf','/assets/pdf/activities/ACHARYA SESSION.pdf'
];
src: string;
isLoading = true;
ngOnInit(): void {
  this.changeRoute('7');
}

changeRoute(year: string) {
  this.isLoading = true;
  if (year == '1') {
    $('.first').addClass('active');
    $('.second').removeClass('active');
    $('.third').removeClass('active');
    $('.fourth').removeClass('active');
    $('.fifth').removeClass('active');
    $('.sixth').removeClass('active');
    $('.seventh').removeClass('active');
    this.src = this.pdfSrc[0];
  }
  if (year == '2') {
    $('.second').addClass('active');
    $('.first').removeClass('active');
    $('.third').removeClass('active');
    $('.fourth').removeClass('active');
    $('.fifth').removeClass('active');
    $('.sixth').removeClass('active');
    $('.seventh').removeClass('active');
    
    this.src = this.pdfSrc[1];
  }
  if (year == '3') {
    $('.third').addClass('active');
    $('.first').removeClass('active');
    $('.second').removeClass('active');
    $('.fourth').removeClass('active');
    $('.fifth').removeClass('active');
    $('.sixth').removeClass('active');
    $('.seventh').removeClass('active');
    
    this.src = this.pdfSrc[2];
  }
  if (year == '4') {
    $('.fourth').addClass('active');
    $('.fifth').removeClass('active');
    $('.third').removeClass('active');
    $('.first').removeClass('active');
    $('.second').removeClass('active');
    $('.sixth').removeClass('active');
    $('.seventh').removeClass('active');
    
    this.src = this.pdfSrc[3];
  }
  if (year == '5') {
    $('.fifth').addClass('active');
    $('.fourth').removeClass('active');
    $('.third').removeClass('active');
    $('.first').removeClass('active');
    $('.second').removeClass('active');
    $('.sixth').removeClass('active');
    $('.seventh').removeClass('active');
    
    this.src = this.pdfSrc[4];
  }
  if (year == '6') {
    $('.sixth').addClass('active');
    $('.fourth').removeClass('active');
    $('.third').removeClass('active');
    $('.first').removeClass('active');
    $('.second').removeClass('active');
    $('.fifth').removeClass('active');
    $('.seventh').removeClass('active');
    
    this.src = this.pdfSrc[5];
  }
  if (year == '7') {
    $('.seventh').addClass('active');
    $('.fourth').removeClass('active');
    $('.third').removeClass('active');
    $('.first').removeClass('active');
    $('.second').removeClass('active');
    $('.fifth').removeClass('active');
    $('.sixth').removeClass('active');
    
    this.src = this.pdfSrc[6];
  }
}
hideLoader() {
  this.isLoading = false;
}
}
