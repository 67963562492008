import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  pdfSrc='/assets/pdf/LIST OF HOLIDAYS.pdf'
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }
}
