<app-banner></app-banner>
<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>Mandatory Public Disclosure</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/mpd/geninfo"><i class="fa-solid fa-angle-right"></i> General Information</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/mpd/docinfo"><i class="fa-solid fa-angle-right"></i> Documents and Information</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/mpd/holiday"><i class="fa-solid fa-angle-right"></i> List Of Holidays</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/mpd/planner"><i class="fa-solid fa-angle-right"></i> CCA Planner</a>
                    </li>
                    <!-- <li routerLinkActive="active">
                        <a routerLink="/mpd/resultacademics"><i class="fa-solid fa-angle-right"></i> Academics </a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a routerLink="/mpd/staffschoolinfra"><i class="fa-solid fa-angle-right"></i> Staff and School Infrastructure</a>
                    </li>
                    <li routerLinkActive="active">
                        <a href="https://www.youtube.com/watch?v=TT6WwjeawXM"><i class="fa-solid fa-angle-right"></i> Inspection</a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>