import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uniform',
  templateUrl: './uniform.component.html',
  styleUrls: ['./uniform.component.scss']
})
export class UniformComponent implements OnInit {
  pdfSrc='/assets/pdf/UNIFORM APS.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
