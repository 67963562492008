import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feestructure',
  templateUrl: './feestructure.component.html',
  styleUrls: ['./feestructure.component.scss']
})
export class FeestructureComponent implements OnInit {
  pdfSrc='/assets/pdf/FEE APS.pdf';
  //'/assets/pdf/feestructure2024-25.pdf';
  // pdfSrc='/assets/pdf/feestructure.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
