<div id="header">
    <div id="top-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class=" d-flex justify-content-end align-items-center">
                        <a routerLink="/alumni"> ALUMNI</a>
                        <div class="vertical-bar rounded mx-1 mx-md-2"></div>
                        <a href="http://school.odpay.in/" target="_blank"> Staff Login</a>
                        <!-- <div class="vertical-bar rounded mx-1 mx-md-2"></div>
                        <a href="">Apply Now</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="middle-header">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9">
                    <img src="/assets/img/logo/3.png" alt="" class="img-fluid">
                </div>
                <div class="col-12 col-md-3 logo d-flex justify-content-center align-items-center h-100">
                    <div class="icons me-5">
                        <a href="https://youtube.com/@aggarsainpublicschoolkuruk8041?si=9_2hivEKo7hISUGI"
                            target="_blank" class="yt"><i class="fab fa-youtube"></i></a>
                    </div>
                    <div class="icons me-5">
                        <a href="https://www.instagram.com/apskurukshetra?igsh=eDI0aG40dWI5dnVs" target="_blank"
                            class="ig"><i class="fab fa-instagram" style="left: 9px;"></i></a>
                    </div>
                    <div class="icons"><a href="https://www.facebook.com/share/RMw9rrgqELTnFfHw/?mibextid=qi2Omg"
                            target="_blank" class="fb"><i class="fab fa-facebook" style="left: 7px;"></i></a></div>
                </div>
            </div>
        </div>
    </div>

    <div id="menu-header">
        <nav class="navbar navbar-expand-lg pt-1">
            <div class="container-fluid">
                <button class="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#menu"
                    aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse text-center" id="menu">
                    <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li class="nav-item hoverMenu">
                            <a class="nav-link active hoverMenuItem" aria-current="page" routerLink="/">Home</a>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" id="aboutDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                About Us
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                                <li><a class="dropdown-item" routerLink="/about/overview"> Overview </a></li>
                                <li><a class="dropdown-item" routerLink="/about/mission">Our Mission</a></li>
                                <li><a class="dropdown-item" routerLink="/about/founder">Founder Members</a></li>
                                <li><a class="dropdown-item" routerLink="/about/committee">Managing Committee</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown infraDropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" href="#" id="labsDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Activities
                            </a>
                            <ul class="dropdown-menu labsDropdownMenu custom-dropdown-menu"
                                aria-labelledby="labsDropdown">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <h6 class="dropdown-header" data-toggle="collapse" data-target="#menu">
                                            Curricular
                                            Activities</h6>
                                        <a class="dropdown-item" routerLink="/activities/ncc">NCC</a>
                                        <a class="dropdown-item" routerLink="/activities/debate">DEBATE &
                                            DECLAMATION</a>
                                        <a class="dropdown-item" routerLink="/activities/workshop">WORKSHOP</a>
                                        <a class="dropdown-item" routerLink="/activities/quiz">Quiz</a>
                                        <a class="dropdown-item" routerLink="/activities/schoolband">OUR SCHOOL BAND</a>
                                        <a class="dropdown-item" routerLink="/activities/sports">Sports</a>
                                        <a class="dropdown-item" routerLink="/activities/camps">Summer Camps</a>
                                        <a class="dropdown-item" routerLink="/activities/annual">Annual Day
                                            Celebration</a>
                                        <a class="dropdown-item" routerLink="/activities/tours"> Tours & excursions </a>
                                        <a class="dropdown-item" routerLink="/activities/awards"> Awards & Scholarship </a>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <a class="dropdown-item" routerLink="/activities/eco-club"> Eco Club </a>
                                        <a class="dropdown-item" routerLink="/activities/prefectorial"> PREFECTORIAL Board </a>
                                        <h6 class="dropdown-header" data-toggle="collapse" data-target="#menu">Glimpses
                                            of Co-Curricular Activities</h6>
                                        <a class="dropdown-item" routerLink="/activities/pre-primary">PRE PRIMARY</a>
                                        <a class="dropdown-item" routerLink="/activities/primary">PRIMARY</a>
                                        <a class="dropdown-item" routerLink="/activities/secondary">SECONDARY</a>
                                    </div>
                                </div>

                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/faculty" class="nav-link">FACULTY</a>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" id="academicDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Academic
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="academicDropdown">
                                <li><a class="dropdown-item" routerLink="/academic/academics">Academics</a></li>
                                <li><a class="dropdown-item" routerLink="/academic/examination">Examination</a></li>
                                <li><a class="dropdown-item" routerLink="/academic/curriculum">Curriculum</a></li>
                                <li><a class="dropdown-item" routerLink="/academic/result">Result</a></li>
                                <li><a class="dropdown-item" routerLink="/academic/slc">SLC Issued</a></li>
                                <li><a class="dropdown-item" routerLink="/academic/cbseres"> CBSE Result </a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" id="admissionDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Admission
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="admissionDropdown">
                                <li><a class="dropdown-item" routerLink="/admission/admissions">ADMISSION OPEN 2025-26</a></li>
                                <li><a class="dropdown-item" routerLink="/admission/feestructure">FEE STRUCTURE</a></li>
                                <li><a class="dropdown-item" routerLink="/admission/uniform">School Uniform</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" id="galleryDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                MANDATORY PUBLIC DISCLOSURE
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="galleryDropdown">
                                <li><a class="dropdown-item" routerLink="/mpd/geninfo">General Information</a></li>
                                <li><a class="dropdown-item" routerLink="/mpd/docinfo">Documents and Information</a>
                                </li>
                                <!-- <li><a class="dropdown-item" routerLink="/mpd/resultacademics">Academics</a>
                                </li> -->
                                <li><a class="dropdown-item" routerLink="/mpd/holiday">List Of Holidays</a>
                                </li>
                                <li><a class="dropdown-item" routerLink="/mpd/planner">CCA Planner</a>
                                </li>
                                <li><a class="dropdown-item" routerLink="/mpd/staffschoolinfra">Staff and School
                                        Infrastructure</a>
                                </li>
                                <li><a class="dropdown-item"
                                        href="https://www.youtube.com/watch?v=TT6WwjeawXM">Inspection</a></li>
                                <li><a class="dropdown-item" href="\assets\pdf\OASIS-FORM.pdf" target="_blank">OASIS Form</a></li>
                                <li><a class="dropdown-item" routerLink="/downloads">Downloads</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" href="#" id="facultyDropdown"
                                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                FEES PAYMENT
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="facultyDropdown">
                                <li><a class="dropdown-item" routerLink="/fees/student">Student App</a>
                                </li>
                                <li><a class="dropdown-item" href="https://app.okiedokiepay.com/#!/65e2d283e933a034b13fe692/entityLandingPage" target="_blank">CLICK To PAY FEES</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown hoverMenu">
                            <a class="nav-link dropdown-toggle hoverMenuItem" href="#" id="facultyDropdown"
                                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                VACANCIES
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="facultyDropdown">
                                <li><a class="dropdown-item" href="/assets/pdf/ApplicationFormForApplied.pdf" target="_blank">Download</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/gallery" class="nav-link">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact-us" class="nav-link">Contact Us</a>
                        </li>

                    </ul>

                </div>
            </div>
        </nav>
    </div>
</div>