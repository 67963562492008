<div id="activities">
    <div class="container-fluid innerPageMenu">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/activities"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5> Annual Day Celebrations </h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')"> Junior Wing </a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')"> Senior Wing </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <div class="card-body">
                                    <p class="inner-lead">The Annual Prize Distribution Function is a much-awaited and
                                        regular event at our school, celebrated over two consecutive days for the Senior
                                        and Junior Wings separately. It is a vibrant cultural extravaganza that
                                        showcases the talents, enthusiasm, and creativity of our students.
                                    </p>
                                    <p class="inner-lead">The Junior Wing takes pride in achieving 100% participation,
                                        where our tiny tots present enthralling performances that highlight their
                                        potential and zeal. This event not only celebrates the accomplishments of our
                                        young achievers but also serves as a platform to recognize the collective
                                        efforts, objectives and milestones of the APS family.
                                    </p>
                                    <p class="inner-lead">Through this occasion, we aim to instill confidence, foster
                                        team spirit and honor excellence across various domains, making it a memorable
                                        experience for all.</p>
                                        
                                    <img [src]="isrc" alt="" class="img-fluid"><br><br>
                                    <!-- <h5 class="card-title text-center">LESSON PLAN(2021-22)</h5> -->
                                    <pdf-viewer [src]="src" [render-text]="true" [original-size]="false"
                                        style="display: block;" (on-progress)="hideLoader()"
                                        (error)="hideLoader()"></pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>